import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import ErrorAlert from "../components/ErrorAlert";
import Loader from "../components/Loader";
import SuccessModal from "../components/SuccessModal";
import { AuthContext } from "../context/AuthContext";
import axiosInstance from "../utils/axiosInstance";

const CreateTicket = () => {
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [createTicketLoading, setCreateTicketLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [successModal, setSuccessModal] = useState(false);
  const [ticket, setTicket] = useState({
    fileId: "",
    ticketId: "",
    subject: "",
    message: "",
    attachment: null,
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTicket({
      ...ticket,
      [name]: value,
    });
  };

  useEffect(() => {
    const fetchFile = async () => {
      try {
        const response = await axiosInstance.get(`/files/${id}`);
        setTicket((prevTicket) => ({
          ...prevTicket,
          fileId: response.data.file.id,
          ticketId: response.data.file.file_id,
          subject: "Ticket ID:" + response.data.file.file_id,
        }));
      } catch (err) {
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchFile();
  }, [id]);

  const handleFileChange = (e) => {
    setTicket({
      ...ticket,
      attachment: e.target.files[0],
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setCreateTicketLoading(true);

    const formData = new FormData();
    formData.append("jsonData", JSON.stringify(ticket));

    if (ticket.attachment) {
      formData.append("file", ticket.attachment);
    }

    try {
      const response = await axiosInstance.post("/tickets", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      setCreateTicketLoading(false);
      setSuccessModal(true);
      setTimeout(() => {
        setSuccessModal(false);
        if (user.role.toUpperCase() === "ADMIN") {
          navigate(`/admin/view-file/${id}`);
        } else {
          navigate(`/view-file/${id}`);
        }
      }, 3000);
    } catch (error) {
      setCreateTicketLoading(false);
      if (
        error.response &&
        error.response.status &&
        error.response.status === 400
      ) {
        setErrorMessage(error.response.data.message);
        return;
      }
      setErrorMessage("Something went wrong");
    }
  };

  if (loading) {
    return (
      <div className="container mx-auto p-6">
        <Loader />
      </div>
    );
  }

  return (
    <div className="p-6 bg-white dark:bg-gray-800 rounded-lg shadow-md">
      <h2 className="text-2xl font-bold text-gray-800 dark:text-gray-200 mb-4">
        Create New Ticket
      </h2>
      {errorMessage && <ErrorAlert errorMessage={errorMessage} />}
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 dark:text-gray-300 mb-2">
            Subject
          </label>
          <input
            type="text"
            name="subject"
            value={ticket.subject}
            onChange={handleChange}
            disabled
            readOnly
            className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-gray-300"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 dark:text-gray-300 mb-2">
            Message
          </label>
          <textarea
            name="message"
            value={ticket.message}
            onChange={handleChange}
            className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-gray-300"
            required
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700 dark:text-gray-300 mb-2">
            Attachment
          </label>
          <input
            type="file"
            name="attachment"
            onChange={handleFileChange}
            className="w-full p-2 border border-gray-300 dark:border-gray-600 rounded bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-gray-300"
          />
        </div>

        <button
          type="submit"
          className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-600"
          disabled={createTicketLoading}
        >
          {createTicketLoading ? "Creating..." : "Create Ticket"}
        </button>
      </form>
      {successModal && (
        <SuccessModal
          message={"Ticket is created successfully"}
          isModal={true}
        />
      )}
    </div>
  );
};

export default CreateTicket;
