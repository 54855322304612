import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import SuccessAlert from "../../components/SuccessAlert";
import ErrorAlert from "../../components/ErrorAlert";
import axiosInstance from "../../utils/axiosInstance";

const EditUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [saving, setSaving] = useState(false);
  const [message, setMessage] = useState();

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axiosInstance.get(`/users/${id}`);
        setUser(response.data);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setMessage("");
    setSaving(true);
    try {
      await axiosInstance.put(`/users/${id}`, user);
      setSaving(false);
      setMessage("User updated");
      navigate("/admin/all-users");
    } catch (error) {
      if (error.response.status === 400) {
        setError(error.response.data.message);
        return;
      }
      setError("Failed to update user.");
      setSaving(false);
    }
  };

  if (loading) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-2xl font-bold mb-4">Edit User</h1>

      <form
        onSubmit={handleSubmit}
        className="bg-white p-6 rounded-lg shadow-md"
      >
        {message && (
          <div>
            <SuccessAlert message={message} />
            <br />
          </div>
        )}
        {error && (
          <div>
            <ErrorAlert errorMessage={error} />
            <br />
          </div>
        )}
        <div className="mb-4">
          <label className="block text-sm mb-2 text-gray-900 dark:text-gray-100">
            Username (Not Changeable)
          </label>
          <div className="w-full p-2 border border-gray-300 dark:border-gray-700 bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-gray-400 rounded">
            {user.username}
          </div>
        </div>

        <div className="mb-4">
          <label className="block text-sm mb-2 text-gray-900 dark:text-gray-100">
            Email (Not Changeable)
          </label>
          <div className="w-full p-2 border border-gray-300 dark:border-gray-700 bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-gray-400 rounded">
            {user.email}
          </div>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Phone</label>
          <input
            type="text"
            name="phone"
            value={user.phone}
            onChange={handleInputChange}
            className="border border-gray-300 p-2 w-full rounded-md"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Country</label>
          <input
            type="text"
            name="country"
            value={user.country}
            onChange={handleInputChange}
            className="border border-gray-300 p-2 w-full rounded-md"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Credit Balance</label>
          <input
            type="number"
            name="credit_balance"
            value={user.credit_balance}
            onChange={handleInputChange}
            className="border border-gray-300 p-2 w-full rounded-md"
          />
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Role</label>
          <select
            name="role"
            value={user.role}
            onChange={handleInputChange}
            className="border border-gray-300 p-2 w-full rounded-md"
          >
            <option value="CUSTOMER">Customer</option>
            <option value="ADMIN">Admin</option>
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-gray-700">Status</label>
          <select
            name="status"
            value={user.status}
            onChange={handleInputChange}
            className="border border-gray-300 p-2 w-full rounded-md"
          >
            <option value="ACTIVE">Active</option>
            <option value="DEACTIVE">Deactive</option>
          </select>
        </div>

        <button
          type="submit"
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          disabled={saving}
        >
          {saving ? "Saving..." : "Save Changes"}
        </button>

        <button
          type="button"
          className="ml-4 bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
          onClick={() => navigate.push(`/admin/users/${id}`)}
        >
          Cancel
        </button>
      </form>
    </div>
  );
};

export default EditUser;
