import React, { useState, useContext } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

const ChooseSolution = ({
  solutions,
  setShowModal,
  setTotalCredits,
  selected,
  setSelected,
  setSelectedOptions,
  selectedOptions,
  limitExceeded,
  setLimitExceeded,
}) => {
  const { user, updateUser, resetUser } = useContext(AuthContext);
  const [credits, setCredits] = useState(user.credit_balance);
  const [remainingCredits, setRemainingCredits] = useState(credits);
  const [dtcValue, setDtcValue] = useState("");
  const [showDtcOffInput, setShowDtcOffInput] = useState(false);

  const handleRadioChange = (solution) => {
    const tuningCredits = solution.credits || 0;
    const optionsCredits = selected.OPTION.reduce((acc, id) => {
      const solution = solutions.find((sol) => sol.id === id);
      return acc + (solution?.credits || 0);
    }, 0);

    const total = tuningCredits + optionsCredits;

    // Check if the total exceeds available credits
    if (total > credits) {
      alert("You have reached the limit of the credits");
      setLimitExceeded(true);
      return; // Do not proceed with updates
    }

    setSelected((prev) => ({ ...prev, TUNING: solution.id }));
    setSelectedOptions((prevSelectedOptions) =>
      prevSelectedOptions.filter(
        (name) =>
          !solutions.some((sol) => sol.type === "TUNING" && sol.name === name)
      )
    );
    setSelectedOptions((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(solution.name)) {
        return prevSelectedOptions.filter((name) => name !== solution.name);
      } else {
        return [...prevSelectedOptions, solution.name];
      }
    });

    calculateTotalCredits(solution.id, selected.OPTION);
  };

  const handleCheckboxChange = (solution) => {
    const isSelected = selected.OPTION.includes(solution.id);
    const updatedOptions = isSelected
      ? selected.OPTION.filter((id) => id !== solution.id)
      : [...selected.OPTION, solution.id];

    // Calculate the new total credits
    const tuningCredits = selected.TUNING
      ? solutions.find((solution) => solution.id === selected.TUNING)
          ?.credits || 0
      : 0;

    const optionsCredits = updatedOptions.reduce((acc, id) => {
      const sol = solutions.find((solution) => solution.id === id);
      return acc + (sol?.credits || 0);
    }, 0);

    const total = tuningCredits + optionsCredits;

    if (total > credits) {
      alert("You have reached the limit of the credits");
      setLimitExceeded(true);
      return;
    }

    if (solution.name === "DTC OFF") {
      const isSelected = selectedOptions.includes("DTC OFF");
      if (!isSelected) {
        setShowDtcOffInput(true);
      } else {
        setSelectedOptions((prev) =>
          prev.filter((option) => !option.startsWith("DTC:"))
        );
        setShowDtcOffInput(false);
      }
    }

    setSelected((prev) => ({ ...prev, OPTION: updatedOptions }));
    setSelectedOptions((prevSelectedOptions) => {
      if (prevSelectedOptions.includes(solution.name)) {
        return prevSelectedOptions.filter((name) => name !== solution.name);
      } else {
        return [...prevSelectedOptions, solution.name];
      }
    });

    calculateTotalCredits(selected.TUNING, updatedOptions);
  };

  const calculateTotalCredits = (tuningId, optionIds) => {
    setLimitExceeded(false);
    const tuningCredits = tuningId
      ? solutions.find((solution) => solution.id === tuningId)?.credits || 0
      : 0;

    const optionsCredits = optionIds.reduce((acc, id) => {
      const solution = solutions.find((sol) => sol.id === id);
      return acc + (solution?.credits || 0);
    }, 0);

    const total = tuningCredits + optionsCredits;
    if (total > credits) {
      alert("You have the reached the limit of the credits");
      setLimitExceeded(true);
      return;
    }

    setRemainingCredits(credits - total);
    setTotalCredits(total);
    handleCreditsUpdate(credits - total);
  };

  const handleDtcValue = () => {
    setSelectedOptions((prev) => [...prev, `DTC:${dtcValue}`]);
  };

  const resetForm = () => {
    resetUser();
    setSelected({ TUNING: null, OPTION: [] });
    setSelectedOptions([]);
    setTotalCredits(0);
    setDtcValue(false);
    setLimitExceeded(false);
  };

  const handleCreditsUpdate = (remainingCredits) => {
    const updatedUser = { ...user, credit_balance: remainingCredits };
    updateUser(updatedUser);
  };

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center dark:bg-gray-900 dark:bg-opacity-75">
      <div className="bg-white dark:bg-gray-800 rounded-lg w-full max-w-xl shadow-lg transition-transform duration-300 ease-in-out">
        <div className="flex justify-between p-4 bg-gray-300 dark:bg-gray-700 rounded-t">
          <h3 className="text-2xl font-semibold text-gray-900 dark:text-gray-100">
            Select Options
          </h3>
          <div className="flex items-center justify-end font-bold text-red-500">
            {remainingCredits === 0 ? "No more credit left" : ""}
          </div>
        </div>

        <div className="h-auto max-h-[80vh] overflow-y-auto px-6 mt-4">
          {/* Radio Buttons styled as 50x50 Tiles */}
          <div className="mb-6">
            <h4 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-2">
              Tuning Options
            </h4>
            <div className="grid grid-cols-3 gap-4">
              {solutions
                .filter((solution) => solution.type === "TUNING")
                .map((solution) => (
                  <label
                    key={solution.id}
                    className="flex flex-col items-center cursor-pointer"
                  >
                    <input
                      type="radio"
                      name="tuning"
                      value={solution.id}
                      onChange={() => handleRadioChange(solution)}
                      checked={selected.TUNING === solution.id}
                      className="sr-only"
                    />
                    <div
                      className={`w-24 h-24 flex justify-center items-center rounded-lg border-4 ${
                        selected.TUNING === solution.id
                          ? "border-red-500 dark:bg-white"
                          : "border-gray-400 dark:bg-white"
                      } transition-colors duration-300 ease-in-out`}
                    >
                      <img
                        className="rounded-lg"
                        src={solution.image_url}
                        alt={solution.name}
                      />
                    </div>
                    <span className="text-gray-700 dark:text-gray-300 text-sm mt-2">
                      {solution.name}
                    </span>
                    <span
                      className={`text-sm font-medium mt-1 px-2 py-1 rounded-md ${
                        selected.TUNING === solution.id
                          ? "bg-red-500 text-white"
                          : "bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-100"
                      }`}
                    >
                      CRS {solution.credits}
                    </span>
                  </label>
                ))}
            </div>
          </div>

          {/* Styled Checkboxes as 50x50 Tiles */}
          <div className="mb-6">
            <h4 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-2">
              Additional Options
            </h4>
            <div className="grid grid-cols-3 gap-4">
              {solutions
                .filter((solution) => solution.type === "OPTION")
                .map((solution) => (
                  <label
                    key={solution.id}
                    className="flex flex-col items-center cursor-pointer"
                  >
                    <input
                      type="checkbox"
                      value={solution.id}
                      onChange={() => handleCheckboxChange(solution)}
                      checked={selected.OPTION.includes(solution.id)}
                      className="sr-only"
                    />
                    <div
                      className={`w-24 h-24 flex justify-center items-center rounded-lg border-4 ${
                        selected.OPTION.includes(solution.id)
                          ? "border-red-500 dark:bg-white"
                          : "border-gray-400 dark:bg-white"
                      } transition-colors duration-300 ease-in-out`}
                    >
                      <img
                        className="rounded-lg"
                        src={solution.image_url}
                        alt={solution.name}
                      />
                    </div>
                    <span className="text-gray-700 dark:text-gray-300 text-sm mt-2 text-center">
                      {solution.name}
                    </span>
                    <span
                      className={`text-sm font-medium mt-1 px-2 py-1 rounded-md ${
                        selected.OPTION.includes(solution.id)
                          ? "bg-red-500 text-white"
                          : "bg-gray-200 dark:bg-gray-700 text-gray-800 dark:text-gray-100"
                      }`}
                    >
                      CRS {solution.credits}
                    </span>
                  </label>
                ))}
            </div>
          </div>
        </div>

        {showDtcOffInput && (
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 dark:bg-gray-900 dark:bg-opacity-75 flex items-center justify-center z-50">
            <div className="bg-white dark:bg-gray-800 rounded-lg shadow-lg w-full max-w-md p-6 relative">
              {/* Modal Content */}
              <div className="mb-6">
                <label className="block font-semibold text-gray-900 dark:text-gray-100">
                  Write DTC
                </label>
                <input
                  type="text"
                  value={dtcValue}
                  onChange={(e) => setDtcValue(e.target.value)}
                  className="border border-gray-300 dark:border-gray-600 w-full p-2 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-400 dark:focus:ring-blue-300 transition-shadow duration-300 ease-in-out bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100"
                  placeholder="Enter DTC (P, U) etc..."
                />
              </div>
              {/* Save Button */}
              <div className="flex justify-end">
                <button
                  onClick={() => {
                    handleDtcValue();
                    setShowDtcOffInput(false);
                  }}
                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-lg shadow-lg transition-transform transform hover:scale-105 duration-300 ease-in-out"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        )}

        {/* Footer */}
        <div className="flex justify-end p-4 bg-gray-200 dark:bg-gray-700 rounded-b">
          <div>
            {(limitExceeded || remainingCredits === 0) && (
              <Link
                to={"/buy-credits"}
                className="bg-green-400 dark:bg-green-600 hover:bg-green-500 dark:hover:bg-green-500 text-white font-bold py-2 px-4 rounded-lg shadow-lg transition-transform transform hover:scale-105 duration-300 ease-in-out"
              >
                Buy Credits
              </Link>
            )}

            <button
              type="button"
              onClick={() => resetForm()}
              className="bg-gray-500 dark:bg-gray-600 hover:bg-gray-600 dark:hover:bg-gray-500 text-white font-bold py-2 px-4 ml-4 rounded-lg shadow-lg transition-transform transform hover:scale-105 duration-300 ease-in-out"
            >
              Reset
            </button>

            <button
              type="button"
              onClick={() => setShowModal(false)}
              className="bg-blue-500 dark:bg-blue-600 hover:bg-blue-600 dark:hover:bg-blue-500 text-white font-bold py-2 px-4 ml-4 rounded-lg shadow-lg transition-transform transform hover:scale-105 duration-300 ease-in-out"
            >
              Save Changes
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseSolution;
