import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import axiosInstance from "../utils/axiosInstance";
import { getStatusBadgeClass } from "../utils/Utils";
import { Link, useParams } from "react-router-dom";
import Loader from "../components/Loader";
import DownloadFileButton from "../components/DownloadFileButton";
import DownloadModFileButton from "../components/DownloadModFileButton";
import CancelFileButton from "../components/CancelFileButton";
import SupportChatBox from "./SupportChatBox";

const FileDetails = () => {
  const { user } = useContext(AuthContext);
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchFiles = async () => {
      try {
        const response = await axiosInstance.get(`/files/${id}`);
        setData(response.data);
      } finally {
        setLoading(false);
      }
    };

    fetchFiles();
  }, []);

  if (loading) {
    return (
      <div className="container mx-auto p-6">
        <Loader />
      </div>
    );
  }

  const { file, vehicle, ticket_id } = data;

  return (
    <div className="p-6 min-h-screen ">
      <div className="flex items-start gap-4 p-4 bg-white dark:bg-gray-900 shadow-md rounded-lg border border-gray-200 dark:border-gray-700 mb-6">
        <div className="text-yellow-500 dark:text-yellow-400">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 16h-1v-4h-.01M12 8h.01M21 12c0 4.97-4.03 9-9 9s-9-4.03-9-9 4.03-9 9-9 9.03 9 9 9z"
            />
          </svg>
        </div>
        <div>
          <p className="text-gray-800 dark:text-gray-200 italic">
            Your file will be reviewed by an admin. If there's an issue with
            File or Solution is unsupported, the process will be canceled, and
            you'll receive an automatic refund (CRS). <br />
            <strong>Notice:</strong> Once the file is completed, it cannot be
            canceled or refunded. For changes or questions, contact us via
            support for a quick response. <br />
            <strong className="text-red-500">Important:</strong> Correct the
            checksum before writing.
          </p>
        </div>
      </div>
      {/* Container for File Details and Vehicle Details */}
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
        {/* File Information Section */}
        <div className="bg-white dark:bg-gray-800 dark:text-gray-100 p-6 rounded-md shadow-sm">
          <h2 className="text-2xl font-bold mb-4">File Information</h2>
          <div className="space-y-2">
            <p>
              <strong>File ID:</strong> {file.file_id}
            </p>
            <p className="truncate" title={`${file.file_name}`}>
              <strong>File Name:</strong> {file.file_name}
            </p>
            <p>
              <strong>Price:</strong>{" "}
              <span className="text-yellow-300">CRS {file.price}</span>
            </p>
            <p>
              <strong>Solution:</strong> {file.solution}
            </p>
            <p>
              <strong>Description:</strong> {file.description}
            </p>
            <p>
              <strong>Reading Device:</strong> {file.reading_device}
            </p>
            <p>
              <strong>Status:</strong>{" "}
              <span
                className={`px-3 py-1 rounded-full text-sm font-medium ${getStatusBadgeClass(
                  file.status
                )}`}
              >
                {file.status}
              </span>
            </p>
            <p>
              <strong>Uploaded:</strong> {file.created_at}
            </p>
            <p>
              <strong>Modified:</strong> {file.updated_at}
            </p>
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800 dark:text-gray-100 p-6 rounded-md shadow-sm">
          <h2 className="text-2xl font-bold mb-4">Files</h2>

          <DownloadFileButton fileId={file.id} fileName={file.file_name} />

          <DownloadModFileButton
            fileId={file.id}
            fileName={file.mod_file_name}
            status={file.status}
          />

          {file.notes && (
            <div
              className="items-center bg-red-50 dark:bg-red-900 border border-red-300 dark:border-red-600 text-red-800 dark:text-red-200 text-sm rounded-lg p-4 mt-8"
              role="alert"
            >
              <div className="flex">
                <svg
                  className="w-5 h-5 mr-2 text-red-600 dark:text-red-300"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M8.257 3.099c.765-1.36 2.721-1.36 3.486 0l7.092 12.614c.75 1.337-.197 2.887-1.743 2.887H2.908c-1.546 0-2.493-1.55-1.743-2.887L8.257 3.1zM11 14a1 1 0 10-2 0 1 1 0 002 0zm-1-8a1 1 0 00-.993.883L9 7v4a1 1 0 001.993.117L11 11V7a1 1 0 00-1-1z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                <span className="font-bold">IMPORTANT</span>
              </div>
              <p>{file.notes}</p>
            </div>
          )}

          <CancelFileButton
            fileId={file.id}
            status={file.status}
            credits={file.price}
            isAdmin={false}
          />
        </div>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 mb-6">
        {/* Vehicle Information Section */}
        <div className="bg-white dark:bg-gray-800 dark:text-gray-100 p-6 rounded-md shadow-sm">
          <h2 className="text-2xl font-bold mb-4">Vehicle Information</h2>
          <div className="space-y-2">
            <p>
              <strong>Type:</strong> {vehicle.type}
            </p>
            <p>
              <strong>Brand:</strong> {vehicle.brand}
            </p>
            <p>
              <strong>Model:</strong> {vehicle.model}
            </p>
            <p>
              <strong>ECU:</strong> {vehicle.ecu}
            </p>
            <p>
              <strong>Generation:</strong> {vehicle.generation}
            </p>
            <p>
              <strong>Gear:</strong> {vehicle.gear}
            </p>
            <p>
              <strong>Petrol Type:</strong> {vehicle.petrol_type}
            </p>
          </div>
        </div>
        <div className="bg-white dark:bg-gray-800 dark:text-gray-100 rounded-md shadow-sm">
          {ticket_id ? (
            <SupportChatBox ticketId={ticket_id} role={user.role} />
          ) : (
            file &&
            ["COMPLETED", "IN_PROGRESS", "PENDING"].includes(file.status) && (
              <div>
                <Link
                  to={`/create-ticket/${file.id}`}
                  className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-lg flex items-center gap-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 121.85 122.88"
                    width="40"
                    height="40"
                    fill="#fff"
                  >
                    <path
                      fillRule="evenodd"
                      d="M42.18,72.75A47.21,47.21,0,0,1,38.28,65c-8.39-4.72-9.73-25.71-4.56-24.12,0-.79-.14-3-.14-4.52,0-34.07,54.74-34.09,54.74,0,0,1.56-.16,3.73-.14,4.52C93.34,39.26,92,60.25,83.62,65c-2.13,5.9-5.93,9.86-5.62,12.45,0,8.89-9.77,12.11-17,12.19-.66,0-1.33,0-2-.1a17.8,17.8,0,0,0,4.52-1.9h0c3.11-1.9,5.21-4.71,5.21-8.08s-2.09-6.2-5.2-8.09h0a18.68,18.68,0,0,0-9.4-2.57,19.54,19.54,0,0,0-7.51,1.35,12.78,12.78,0,0,0-4,2.58l-.37,0Zm58.25-41.14h-.37C96.63,17.08,87.81,7,77.3,2.83A44.05,44.05,0,0,0,58,.15a46.75,46.75,0,0,0-18.44,5.4A34.24,34.24,0,0,0,22.15,31.61h-.69c-3.3,0-6.64,2.71-6.64,6V59.28c0,3.3,3.34,6,6.64,6h1.95C25.54,73.93,34.17,81.85,43,81.85h1.73c1.51,2.22,4.44,3.79,9.32,3.79s10.08-2.75,10.08-6.13S59,73.39,54.05,73.39,46.1,75,44.64,77.32H43c-7.54,0-15-8-15.53-15.22V32A29.1,29.1,0,0,1,42.13,10.2,41.46,41.46,0,0,1,58.4,5.47,38.8,38.8,0,0,1,75.33,7.79c8.71,3.44,16.06,12,19.23,23.82h-.13V65.28h6c3.3,0,6.64-2.7,6.64-6V37.62c0-3.31-3.34-6-6.64-6ZM64.59,104.44h1.86a3,3,0,0,0,3-3v-5a3,3,0,0,0-3-3h-11a3.05,3.05,0,0,0-3,3v5a3.06,3.06,0,0,0,3,3H57.3l-3.64,18.43H68.07l-3.48-18.44ZM0,122.88c1.43-18.54-2.21-17.79,13.32-23.6a128.67,128.67,0,0,0,22.78-11l13.27,34.63ZM86.4,86.67a95.25,95.25,0,0,0,21.07,10c14.5,4.82,14.5,5.5,14.36,26.17H72.65L86.4,86.67Z"
                    />
                  </svg>
                  Open Support Ticket
                </Link>
              </div>
            )
          )}
        </div>
      </div>
    </div>
  );
};

export default FileDetails;
