import React from "react";

const ProgressBar = ({ progress }) => {
  return (
    <div className="w-full bg-gray-300 h-3 overflow-hidden relative">
      <div
        className="relative h-full overflow-hidden"
        style={{ width: `${progress}%` }}
      >
        <div className="absolute top-0 left-0 w-full h-full bg-blue-500 wave-animation"></div>
      </div>
    </div>
  );
};

export default ProgressBar;
