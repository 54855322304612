import React, { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import PayPalForm from "../components/PayPalForm";
import SuccessModal from "../components/SuccessModal";
import { AuthContext } from "../context/AuthContext";
import axiosInstance from "../utils/axiosInstance";

const BuyCredits = () => {
  const { user, updateUser, updateUserLocal } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const myBooleanParam = queryParams.get("success") === "true";

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axiosInstance.get("/users/my");
        const updatedUser = {
          ...user,
          credit_balance: response.data.credit_balance,
        };
        updateUser(updatedUser);
        updateUserLocal();
      } finally {
        // Optionally handle any cleanup or final logic here
      }
    };
    fetchUserData();
  }, []);

  useEffect(() => {
    if (myBooleanParam) {
      const timer = setTimeout(() => {
        navigate("/upload");
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [myBooleanParam, navigate]);

  return (
    <div>
      <div className="flex items-start gap-4 p-4 bg-white dark:bg-gray-900 shadow-md rounded-lg border border-gray-200 dark:border-gray-700 mb-4">
        <div className="text-yellow-500 dark:text-yellow-400">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 16h-1v-4h-.01M12 8h.01M21 12c0 4.97-4.03 9-9 9s-9-4.03-9-9 4.03-9 9-9 9.03 9 9 9z"
            />
          </svg>
        </div>
        <div>
          <p className="text-gray-800 dark:text-gray-200 italic">
            Thank you for trusting <strong>VDP Tuning!</strong> Your payment
            will be securely processed via PayPal, and credits will
            automatically be converted into CRS and added to your VDP Tuning
            account.
          </p>
        </div>
      </div>
      <div className="max-w-md mt-8 mx-auto bg-gray-100 dark:bg-gray-600 text-gray-900 dark:text-gray-200 rounded text-center mb-8">
        {myBooleanParam ? (
          <SuccessModal message={"You bought credits successfully"} />
        ) : (
          <div className="py-8">
            <strong className="text-2xl">1 CRD = 1 EUR</strong>
            <h2 className="text-2xl mb-4">Buy Credits</h2>
            <PayPalForm userId={user.id} />
          </div>
        )}
      </div>
    </div>
  );
};

export default BuyCredits;
