import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import axiosInstance from "../utils/axiosInstance";
import ErrorAlert from "../components/ErrorAlert";
import SuccessAlert from "../components/SuccessAlert";
import logo from "../assets/logo.png";
import { AuthContext } from "../context/AuthContext";
import Loader from "../components/Loader";

const Login = () => {
  const { background, isBackgroundLoaded } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    setMessage(null);
    setErrorMessage(null);
    e.preventDefault();
    try {
      setLoading(true);
      await axiosInstance.post("/auth/request-reset-password", {
        email: email,
      });
      setMessage("Reset link has been sent to your email.");
      setLoading(false);
      setEmail("");
    } catch (err) {
      setLoading(false);
      if (err.response.status === 400) {
        setErrorMessage(err.response.data.message);
        return;
      }
      setErrorMessage("There was a problem sending the reset link.");
    }
  };

  return (
    <div className={`min-h-screen flex bg-white dark:bg-gray-900`}>
      {/* Left Section - Wallpaper */}
      <div className="relative w-2/3 bg-gray-700">
        {!isBackgroundLoaded ? (
          <div className="absolute inset-0 flex items-center justify-center bg-white z-10">
            <Loader />
          </div>
        ) : background.type === "IMAGE" ? (
          <div
            className={`w-full h-full bg-cover bg-center transition-opacity duration-500 ${
              isBackgroundLoaded ? "opacity-100" : "opacity-0"
            }`}
            style={{
              backgroundImage: `url("${background?.url}")`,
            }}
          ></div>
        ) : (
          <video
            className={`w-full h-full bg-cover bg-center transition-opacity duration-500 ${
              isBackgroundLoaded ? "opacity-100" : "opacity-0"
            }`}
            width="600"
            autoPlay
            muted
            loop
            preload="metadata"
          >
            <source src={background?.url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </div>

      {/* Right Section - Login Form */}
      <div className="w-2/3 flex items-center justify-center">
        <div className="w-full max-w-md px-8 py-10 bg-white shadow-lg rounded-lg dark:bg-gray-800">
          {/* Logo */}
          <div className="flex flex-col items-center mb-4">
            <img src={logo} alt="Logo" className="w-12 h-auto mb-4" />
            <h1 className="text-3xl font-bold text-red-600">VDP Tuning Team</h1>
          </div>
          <p className="text-sm text-center text-gray-600 dark:text-gray-400 mb-6">
            Please enter your email to search for your account.
          </p>

          {message && <SuccessAlert message={message} />}
          {errorMessage && <ErrorAlert errorMessage={errorMessage} />}
          <br />
          {/* Form */}
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <input
                type="email"
                placeholder="Email"
                value={email}
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                required
                className="w-full px-4 py-2 border border-gray-300 rounded-md dark:bg-gray-700 dark:border-gray-600 dark:text-white focus:ring-red-500 focus:border-red-500"
              />
            </div>
            <div>
              <button
                className="w-full py-2 bg-red-600 text-white font-bold rounded-md hover:bg-red-700 dark:bg-red-500 dark:hover:bg-red-600"
                disabled={loading}
              >
                {loading ? "Sending..." : "Reset Password"}
              </button>
            </div>
          </form>

          <div className="mt-4 text-center">
            <p className="text-gray-600 dark:text-gray-400">
              Back to{" "}
              <Link to={`/login`} className="text-red-600 hover:underline">
                Login
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
